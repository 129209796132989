import Logo from "../Brand/Logo/Logo";
import Button from "../Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext/AuthContext";
import useLoginModal from "../../hooks/useLoginModal";
import { useEffect, useState } from "react";
import UserMenu from "./UserMenu";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import SearchLocations from "../GoogleMaps/LocationSearch/SearchLocations";
import UserIconCircle from "../../assets/icons/icon-user-circle.svg";
import { Link } from "react-router-dom";
import DashboardSideNav from "../../components/Dashboard/DashboardSideNav";
import HeaderRoleDisplay from "./HeaderRoleDisplay";
import { usePageContext } from "../../context/PageContext/PageContext";

const HeaderNav = ( { role } ) => {
    const { showLoginModal, LoginModalComponent } = useLoginModal();
    const { user, logOut, isAuthLoading, LogOutModalComponent } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const { mobileSidebarOpen, setMobileSidebarOpen, setShowDesktopSideNav } = usePageContext();

    useEffect(() => {
        if (isAuthLoading === false && !user && location.pathname === '/login') {
            showLoginModal();
            setShowDesktopSideNav(false); // Hide the desktop side nav when the login modal is open
        } else if (user && location.pathname === '/login') {
            navigate('/');
        }
    }, [location, showLoginModal, isAuthLoading]);

    const userMenuLinks = {
        "HOST_ADMIN": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'Properties', action: () => navigate('/dashboard/properties') },
            { name: 'Reservations', action: () => navigate('/dashboard/reservations'), divider: true },
            { name: 'Payout Details', action: () => navigate('/dashboard/payout-details') },
            { name: 'My Profile', action: () => navigate('/profile') },
            { name: 'Company Settings', action: () => navigate('/company'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
        "PROPERTY_MANAGER": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'Properties', action: () => navigate('/dashboard/properties') },
            { name: 'Reservations', action: () => navigate('/dashboard/reservations'), divider: true },
            { name: 'My Profile', action: () => navigate('/profile'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
        "FLEET_MANAGER": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'Fleets', action: () => navigate('/dashboard/fleet') },
            { name: 'Reservations', action: () => navigate('/dashboard/reservations'), divider: true },
            { name: 'Payment Methods', action: () => navigate('/dashboard/payment-methods') },
            { name: 'My Profile', action: () => navigate('/profile'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
        "CARRIER_ADMIN": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'Fleets', action: () => navigate('/dashboard/fleet') },
            { name: 'Reservations', action: () => navigate('/dashboard/reservations') },
            { name: 'Payment Methods', action: () => navigate('/dashboard/payment-methods'), divider: true },
            { name: 'My Profile', action: () => navigate('/profile') },
            { name: 'Company Settings', action: () => navigate('/company'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
        "DRIVER": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'Reservations', action: () => navigate('/dashboard/reservations'), divider: true },
            { name: 'Payment Methods', action: () => navigate('/dashboard/payment-methods') },
            { name: 'My Profile', action: () => navigate('/profile'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
        "PARKPRO_ADMIN": [
            { name: 'Dashboard', action: () => navigate('/dashboard') },
            { name: 'My Profile', action: () => navigate('/profile'), divider: true },
            { name: 'Sign out', action: logOut },
        ],
    }

    const userMenuData = {
        user: user,
        links: userMenuLinks[role] || [
            { name: 'Sign out', action: logOut },
        ],
    };

    const toggleMobileSidebar = () => {
        setMobileSidebarOpen(!mobileSidebarOpen);
    };

    return (
        <>
            <div className="flex items-center justify-between space-x-0 lg:space-x-2">
                <div className="flex-1 flex items-center">
                    <Logo
                        link='/'
                        idValue='header-logo'
                        variant='stacked'
                        inverse
                        imgClasses='h-12 drop-shadow-[0_5px_4px_rgba(0,0,0,0.3)] hover:drop-shadow'
                        containerClasses='inline-block max-lg:order-2 mx-auto lg:ml-0 lg:mr-0'
                        border='2'
                    />
                    <button type="button" className="text-white lg:hidden max-lg:order-1 rounded-lg p-1.5 active:bg-secondary/40 -ml-1.5" onClick={() => toggleMobileSidebar()}>
                        { mobileSidebarOpen ? (
                            <>
                                <span className="sr-only">Close sidebar</span>
                                <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                            </>
                        ) : (
                            <>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                            </>
                        )}
                    </button>
                    {user && (
                        <HeaderRoleDisplay />
                    )}
                </div>

                {/* TODO: We can probably combine these two so we don't need to double include the component */}

                {/* Mobile Only */}
                <div className="fixed left-0 top-20 z-30 w-full flex lg:hidden bg-white border-b-2 border-primary h-14 items-center justify-center">
                    <SearchLocations searchInputPlaceholder={"Search destinations"} searchInputFillContainer={true} />
                </div>

                {/* Desktop Only */}
                <div className="flex-auto mx-auto hidden lg:flex justify-center">
                    <SearchLocations searchInputPlaceholder={"Search destinations"} />
                </div>

                <div className="flex lg:flex-1 justify-end">
                    {!user && (
                        <div className="flex items-center space-x-2 lg:space-x-6">
                            <Button
                                onClick={() => navigate("/register")}
                                variant={"primary"}
                                size={"small"}
                                className={"hidden lg:inline-block px-8"}
                            >
                                Sign Up For Free
                            </Button>
                            {/* Fake user menu icon that opens the login modal when clicked */}
                            <div
                                onClick={showLoginModal}
                                className="relative cursor-pointer flex items-center rounded-full h-10 w-10"
                            >
                                <img src={UserIconCircle} alt="User" className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-white" />
                                <div className="absolute right-1 top-1 md:right-0 md:top-0 bg-primary rounded-full h-3 w-3 border-white border-2"></div>
                            </div>
                        </div>
                    ) || (
                        <>
                            <div className="hidden lg:inline-block mr-4">
                                <button
                                    onClick={() => navigate("/dashboard")}
                                    className="px-6 h-10 rounded-md text-white text-sm font-semibold pt-0.5 transition bg-secondary/40 hover:bg-secondary active:bg-secondary/60"
                                >
                                    My Dashboard
                                </button>
                            </div>
                            <UserMenu {...userMenuData} />
                        </>
                    )}
                </div>
            </div>
            <LoginModalComponent />
            <LogOutModalComponent />
        </>
    );
};

export default HeaderNav;
