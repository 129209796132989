import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import deadEnd from "../../assets/images/dead-end-404.png";

const NotFoundPage = () => {
    const navigate = useNavigate();

    const goToPreviousPage = () => {
        navigate(-1);
    };

    return (
            <main className="grid min-h-full place-items-center bg-white px-6">
                <div className="text-center">
                    <div id="sign"><div id="text" data-sign-text='DEAD END'></div></div>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Oops! Lost Your Way?
                    </h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        Looks like the road you're on doesn't lead anywhere.

                        But don't worry, we know how to get you back on track!
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button onClick={goToPreviousPage} variant="primary" size="small">
                            Return to the Last Stop
                        </Button>
                        <Link to="/">
                            <Button variant="text-link-nodecorations">Head Back Home <span aria-hidden="true">&rarr;</span></Button>
                        </Link>
                    </div>
                </div>
            </main>

    );
};

export default NotFoundPage;
