import React from 'react'

const FormRadio = (props) => {
    return (
        <div className='flex items-center gap-x-3'>
            {props.label ? (
                <input
                    className='peer h-4 w-4 rounded-full border-gray-400 text-gray-900 focus:ring-gray-900 invalid:before:w-4 invalid:before:h-4 invalid:before:shadow-invalid invalid:before:rounded-full invalid:before:absolute'
                    type="radio"
                    id={props.idValue}
                    name={props.name}
                    disabled={props.isDisabled}
                    required={props.required}
                    defaultChecked={props.checked}
                    aria-invalid={props.isInvalid}
                    {...props}
                />
            ) : null}
            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className='p text-gray-900 peer-focus:text-black peer-disabled:text-gray-200 peer-invalid:text-red'
                >
                    {props.label}
                </label>
            ) : null}
        </div>
    )
}

export default FormRadio
