import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal/Modal';
import LoginForm from '../components/LoginForm/LoginForm';
import { useNavigate, useLocation } from "react-router-dom";
import PasswordResetForm from '../components/PasswordResetForm/PasswordResetForm';

// Modal Component
const LoginModal = ({ show, hideLoginModal }) => {
    const[showPasswordReset, setShowPasswordReset] = useState(false)
    if (!show) return null;

    return (
        <Modal show={show} handleClose={hideLoginModal} allowCloseVeilClick={true}>
            <div className="p-4">
                {showPasswordReset ?
                    <>
                    <h3 className="text-2xl font-bold mb-4">Reset your password</h3>
                    <PasswordResetForm/>
                    </>
                    :
                    <>
                    <h3 className="text-2xl font-bold mb-4">Sign in to your account</h3>
                    <LoginForm handleShowReset={() => setShowPasswordReset(true)} onSuccessCallback={hideLoginModal}/>
                    </>
                }
            </div>
        </Modal>
    );
};

// Custom Hook
const useLoginModal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);

    const showLoginModal = () => setShow(true);
    const hideLoginModal = () => {
        if (location.pathname === '/login') {
            navigate('/');
        }
        setShow(false);
    };

    return {
        showLoginModal,
        hideLoginModal,
        LoginModalComponent: () => <LoginModal show={show} hideLoginModal={hideLoginModal} />,
    };
};

export default useLoginModal;
