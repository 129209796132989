import React from 'react'

const FormCheckbox = (props) => {
    const baseClasses = 'peer h-4 w-4 rounded border-gray-400 text-gray-900 shadow-sm focus:ring-gray-900 invalid:shadow-invalid'
    const combinedClasses = props.className ? `${baseClasses} ${props.className}` : baseClasses
    return (
        <div className='flex items-center gap-x-3'>
            {props.label ? (
                <input
                    className={combinedClasses}
                    type="checkbox"
                    id={props.idValue}
                    name={props.name}
                    disabled={props.isDisabled}
                    required={props.required}
                    defaultChecked={props.checked}
                    aria-invalid={props.isInvalid}
                    {...props}
                />
            ) : null}
            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className='p text-sm text-gray-900 peer-focus:text-black peer-disabled:text-gray-200 peer-invalid:text-red'
                >
                    {props.label}
                </label>
            ) : null}
        </div>
    )
}

export default FormCheckbox
