import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useAuth } from "../../context/AuthContext/AuthContext";

import HeaderNav from '../../components/HeaderNav/HeaderNav';
import DashboardSideNav from '../../components/Dashboard/DashboardSideNav';
import { useLocation } from 'react-router-dom';

const PageContext = createContext();

export const PageProvider = ({ children }) => {
    const { profile } = useAuth();
    const location = useLocation();

    const [showTopNavigation, setShowTopNavigation] = useState(true);
    const [showDesktopSideNav, setShowDesktopSideNav] = useState(true);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

    const [pageTitle, setPageTitle] = useState(document.title);
    const defaultTitle = useRef(document.title);

    const pageTitleSuffix = ' - Park Pro';
    useEffect(() => {
        document.title = pageTitle ? `${pageTitle}${pageTitleSuffix}` : 'Park Pro';
        return () => {
            // Revert the title to the default when the component unmounts
            document.title = defaultTitle.current || 'Park Pro';
        };
    }, [pageTitle]);

    // Reset state when the route changes
    useEffect(() => {
        setShowTopNavigation(true);
        setShowDesktopSideNav(true);
        setMobileSidebarOpen(false);
        setPageTitle(null);
    }, [location.pathname]);

    return (
        <PageContext.Provider value={{ setPageTitle, showTopNavigation, setShowTopNavigation, showDesktopSideNav, setShowDesktopSideNav, mobileSidebarOpen, setMobileSidebarOpen }}>
            <div className={`flex flex-col h-full font-['Oxanium']`}>
                { showTopNavigation &&
                    <nav>
                        <div className="fixed w-full z-20 mx-auto py-4 md:px-6 px-4 h-20 bg-secondary-dark">
                            <HeaderNav role={profile?.roles?.primary} />
                        </div>
                    </nav>
                }
                <div className={`${showTopNavigation ? 'pt-[118px] lg:pt-20' : ''} flex flex-row h-full`}>
                    <DashboardSideNav role={ profile?.roles?.primary } />
                    <div className="w-full flex flex-col overflow-y-auto">
                        {children}
                    </div>
                </div>
            </div>
        </PageContext.Provider>
    );
};

export const usePageContext = () => useContext(PageContext);