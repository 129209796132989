import { useEffect } from "react";

const ThreeDotsLoader = ({size = 'small'}) => {
  useEffect(() => {
    const runAnimation = () => {
      const dots = document.querySelectorAll(".dot");
      let i = 0;

      const interval = setInterval(() => {
        // First, reset all dots' opacity to ensure only one dot is opaque at a time.
        dots.forEach((dot) => (dot.style.opacity = 0.4));

        // Then, set the current dot's opacity to 1.
        dots[i].style.opacity = 1;

        i++;

        // Reset the index if it's equal to the dots' length
        if (i === dots.length) {
          i = 0;
        }
      }, 200);

      return () => clearInterval(interval);
    };
    runAnimation();
  }, []);

  const smallComponent = (
    <div className="flex justify-center items-center space-x-0.5">
      <div className="dot w-1.5 h-1.5 bg-white rounded-full opacity-1 transition-all duration-150"></div>
      <div className="dot w-1.5 h-1.5 bg-white rounded-full opacity-40 transition-all duration-150"></div>
      <div className="dot w-1.5 h-1.5 bg-white rounded-full opacity-40 transition-all duration-150"></div>
    </div>
  );

  const largeComponent = (
    <div className="flex justify-center items-center space-x-1">
      <div className="dot w-2 h-2 bg-gray-600 rounded-full opacity-1 transition-all duration-150"></div>
      <div className="dot w-2 h-2 bg-gray-600 rounded-full opacity-40 transition-all duration-150"></div>
      <div className="dot w-2 h-2 bg-gray-600 rounded-full opacity-40 transition-all duration-150"></div>
    </div>
  );

    return (
      <>
        {size === 'small' ? smallComponent : largeComponent}
      </>
    );
};

export default ThreeDotsLoader;