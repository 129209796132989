import { useAuth } from "../../context/AuthContext/AuthContext";

import { roleNameMap } from "../../constants/roles.js";

const HeaderRoleDisplay = () => {
    const { profile, isAuthLoading, error } = useAuth();

    if (isAuthLoading) {
        return (
            <div className="hidden lg:flex animate-pulse space-x-4">
                <div className="ml-6 h-4 bg-white/15 rounded-md w-20"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="ml-6 text-red-600 text-sm font-semibold leading-4 tracking-widest uppercase">
                Error
            </div>
        );
    }

    if (profile?.roles?.primary) {
        let roleDisplayName = roleNameMap[profile.roles.primary].shortName;
        if (roleDisplayName.includes(" ")) {
            roleDisplayName = roleDisplayName.split(" ").join("<br />");
        }
        return (
            <div
                className="ml-6 text-white text-sm font-semibold leading-4 tracking-widest uppercase hidden lg:block"
                dangerouslySetInnerHTML={{ __html: roleDisplayName }}
            ></div>
        );
    }

    return null;
};

export default HeaderRoleDisplay;
