import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Logo from "../assets/images/error-fallback-logo.png";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-secondary-dark text-white p-6">
            <XMarkIcon className="absolute top-4 right-4 h-10 w-10 cursor-pointer" onClick={resetErrorBoundary} />
            <img src={Logo} alt="ParkPro Logo" className="h-16 w-auto mb-10" />
            <div className="flex items-center mb-4">
                <h1 className="text-4xl font-extrabold text-white">Something Went Wrong</h1>
            </div>
            <p className="text-lg text-gray-200 font-medium mb-6">
                We encountered an unexpected error. Our team has been notified and is working to resolve the issue.
            </p>
            <p className="text-sm text-gray-400 italic mb-8">
                Error Details: {error.message}
            </p>
            <div className="flex space-x-4">
                <button
                    onClick={resetErrorBoundary}
                    className="inline-flex items-center px-6 py-3 text-lg font-medium rounded-md shadow-sm text-secondary-dark bg-white hover:bg-gray-300 transition duration-200"
                >
                    Try Again
                </button>
                <a
                    href="/"
                    className="inline-flex items-center px-6 py-3 text-lg font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark transition duration-200"
                >
                    Back to Home
                </a>
            </div>
            <div className="mt-10 text-sm text-gray-300">
                If the issue persists, please contact our support team at <a href="mailto:support@parkpro.com" className="text-white underline hover:no-underline">support@park.pro</a>.
            </div>
        </div>
    );
};

export default ErrorFallback;
