import React from 'react'

const FormSelect = (props) => {
    return (
        <div className={`relative flex ${props.displayRow ? 'flex-row items-center' : 'flex-col'}`}>
            <select
                id={props.idValue}
                name={props.name}
                className="peer order-2 block w-auto rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6 invalid:ring-red disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed"
                value={props.value}
                onChange={props.onChange}
            >
                {React.Children.map(props.children, (child) => (
                    <option key={child?.props.value} value={child?.props.value} disabled={child?.props.disabled} selected={props?.value === child?.props.value}>
                        {child.props.children}
                    </option>
                ))}
            </select>

            {props.errorMsg ? (
                <p className='mt-2 order-3 invisible peer-placeholder-shown:!invisible peer-placeholder-shown:!h-px peer-invalid:visible peer-invalid:h-4 h-px text-red text-sm transition-all'>
                    {props.errorMsg}
                </p>
            ) : null}

            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className={
                        props.showLabel
                            ? `p order-1 block text-sm font-medium leading-6 text-gray-900 peer-invalid:text-red ${props.displayRow ? 'mr-2' : 'mb-2'}`
                            : 'sr-only'
                    }
                >
                    {props.label} {props.required ? '' : <span className="text-gray-500 text-xs ml-3"> (optional)</span>}
                </label>
            ) : null}
        </div>
    )
}

export default FormSelect
