import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FormDropdown = (props) => {
  const [selected, setSelected] = useState(props.children[0].props.children)

  return (
    <Listbox disabled={props.isDisabled} aria-invalid={props.isInvalid} required={props.required} value={props.value} onChange={props.onChange}>
      {({ open }) => (
        <>
        {props.label ? (
            <Listbox.Label htmlFor={props.idValue} className={
                props.showLabel
                    ? 'p order-1 text-gray-900 peer-disabled:text-gray-200 peer-invalid:text-red mb-2'
                    : 'sr-only'
            }>{props.label}</Listbox.Label>
            ) : null}
          <div id={props.idValue} name={props.name} className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{props.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {props.children.map((child) => (
                <Listbox.Option
                    key={child.props.value}
                    className={({ active }) =>
                        classNames(
                            active ? 'bg-red-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4'
                        )
                    }
                    value={child.props.value}
                >
                    {({ selected, active }) => (
                        <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {child.props.children}
                            </span>

                            {selected ? (
                                <span
                                    className={classNames(
                                        active ? 'text-white' : 'text-red-600',
                                        'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                    )}
                                >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                        </>
                    )}
                </Listbox.Option>
            ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default FormDropdown;