import React from 'react'

const file = (props) => {
    const labelClasses = 'p order-1 text-gray-900 peer-invalid:text-red mb-2 cursor-pointer underline hover:text-red-500'

    const combinedLabelClasses = props.showLabel ? `${labelClasses} ${props.labelClassName}` : 'sr-only'
return (
        <div className='relative flex flex-col'>
            <input
                type={props.type}
                id={props.idValue}
                name={props.name}
                placeholder={props.placeholder}
                disabled={props.isDisabled}
                aria-invalid={props.isInvalid}
                required={props.required}
                pattern={props.pattern}
                defaultValue={props.defaultValue}
                {...props}
            />
            {props.errorMsg ? (
                <p className='mt-2 order-3 invisible peer-placeholder-shown:!invisible peer-placeholder-shown:!h-px peer-invalid:visible peer-invalid:h-4 h-px text-red text-sm transition-all'>
                    {props.errorMsg}
                </p>
            ) : null}

            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className={
                        combinedLabelClasses
                    }
                >
                {props.iconLeft && props.iconLeft}
                    {props.label}
                </label>
            ) : null}
        </div>
    )
}

export default file