import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../utils/axios';
import { useAuth } from "../../context/AuthContext/AuthContext";
import { Link } from "react-router-dom";
import Button from '../../components/Button/Button';
import FormElement from '../Forms/Forms';

const LoginForm = ({ handleShowReset, onSuccessCallback }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [keepLoggedIn, setKeepLoggedIn] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { setUser } = useAuth();
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    // Parse the URL search string
    const queryParams = new URLSearchParams(location.search);
    let redirect = queryParams.get('redirect') || '/';

    const handleLoginError = (err) => {
        console.error(err);
        setLoginError(err);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError(null); // Clear any previous login errors

        if (email.trim().length === 0 || password.trim().length === 0) {
            handleLoginError("Email and password cannot be empty.");
            return;
        }

        setIsLoading(true); // start loading

        const requestPromise = axios.post('/auth/login', {
            email: email,
            password: password,
            keepLoggedIn: keepLoggedIn,
        });

        try {
            const response = await requestPromise;
            if (response.status === 200) {
                    setUser(response.data.user);

                if (onSuccessCallback) onSuccessCallback(response); // This could be improved

                // Check for a redirect URL in the response
                if (response.data.redirect) {
                    redirect = response.data.redirect;
                }

                // Conditional to prevent potential navigation looping
                if (redirect && redirect !== '/' && redirect !== '/login' && redirect !== '/register') {
                    navigate(redirect);
                }

            } else {
                alert(response.msg);
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error("Login Error", err);
            const errorMessage = err?.response?.data?.error || err?.response?.data?.msg || 'Login failed. Please try again.';
            handleLoginError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-left">
                <form className="space-y-6" onSubmit={handleLogin}>
                    <div>
                        <FormElement
                            id="email"
                            name="email"
                            type="email"
                            variant="input"
                            autoComplete="email"
                            label="Email address"
                            showLabel={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                            required
                        />
                    </div>

                    <div>
                        <FormElement
                            id="password"
                            name="password"
                            type="password"
                            variant="input"
                            autoComplete="current-password"
                            label="Password"
                            showLabel={true}
                            value={password}
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }
                            required
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FormElement
                                id="keepLoggedIn"
                                name="keepLoggedIn"
                                defaultChecked={keepLoggedIn}
                                label="Keep me logged in for 30 days"
                                showLabel={true}
                                onChange={(e) => setKeepLoggedIn(e.target.checked)}
                                variant="checkbox"
                            />
                        </div>
                        <div className="text-sm">
                            <a
                                onClick={handleShowReset}
                                className="font-semibold text-black hover:text-red-700 cursor-pointer"
                            >
                                Forgot password?
                            </a>
                        </div>
                    </div>

                    {loginError && (
                        <div className="text-red-500 text-sm font-semibold mt-2 text-center">
                            {loginError}
                        </div>
                    )}

                    <div>
                        <Button
                            type="submit"
                            variant="primary"
                            size="normal"
                            className="w-full"
                            loading={isLoading}
                        >
                            Sign in
                        </Button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    New to Park Pro?{" "}
                    <Link
                        to="/register"
                        className="font-semibold leading-6 text-black hover:text-red-700"
                    >
                        Get started for free
                    </Link>
                </p>
            </div>
        </>
    );
};

export default LoginForm;