import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "./context/AuthContext/AuthContext";
import PrivateWrapper from "./components/PrivateWrapper/PrivateWrapper";
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import PublicWrapper from './components/PublicWrapper/PublicWrapper';
import { Navigate } from 'react-router-dom';
import { LocationProvider } from './context/LocationContext/LocationContext';
import { PageProvider } from './context/PageContext/PageContext';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import SuspenseFallback from './components/SuspenseFallback';

// Lazy-loaded components

// Authentication and User Management
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage/RegisterPage'));
const PasswordResetPage = lazy(() => import('./pages/PasswordResetPage/PasswordResetPage'));
const PasswordResetConfirmPage = lazy(() => import('./pages/PasswordResetConfirmPage/PasswordResetConfirmPage'));
const OnboardingEmailVerify = lazy(() => import('./pages/Onboarding/OnboardingEmailVerify'));
const AccountInReviewPage = lazy(() => import('./pages/AccountInReviewPage/AccountInReviewPage'));

// Dashboard and Admin
const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'));
const DashboardPropertiesPage = lazy(() => import('./pages/Dashboard/DashboardPropertiesPage'));
const PropertyApprovalTablePage = lazy(() => import('./pages/Admin/PropertyApprovalTablePage'));
const PropertyApprovalPage = lazy(() => import('./pages/Admin/PropertyApprovalPage'));
const CompanyPage = lazy(() => import('./pages/ProfilePage/CompanyPage'));
const AmenitiesPage = lazy(() => import('./pages/Admin/AmenitiesPage'));
const UserDetailsPage = lazy(() => import('./pages/Dashboard/UserDetailsPage'));
const AccountApprovalPage = lazy(() => import('./pages/Admin/AccountApprovalPage'));
const AccountApprovalTablePage = lazy(() => import('./pages/Admin/AccountApprovalTablePage'));

// Property Management
const NewPropertyPage = lazy(() => import('./pages/NewPropertyPage/NewPropertyPage'));
const PropertyPage = lazy(() => import('./pages/PropertyPage/PropertyPage'));
const EditPropertyPage = lazy(() => import('./pages/EditPropertyPage/EditPropertyPage'));
const EditPropertyAvailabilityPage = lazy(() => import('./pages/Dashboard/EditPropertyAvailabilityPage'));

// Fleet Management
const NewFleetPage = lazy(() => import('./pages/NewFleetPage/NewFleetPage'));
const FleetMemberListPage = lazy(() => import('./pages/FleetMemberListPage/FleetMemberListPage'));
const DriverOnboardingPage = lazy(() => import('./pages/DriverOnboardingPage/DriverOnboardingPage'));
const EditFleetPage = lazy(() => import('./pages/EditFleetPage/EditFleetPage'));
const DashboardFleetsPage = lazy(() => import('./pages/DashboardFleetsPage/DashboardFleetsPage'));

// Booking and Reservations
const PropertyBookPage = lazy(() => import('./pages/PropertyBookPage/PropertyBookPage'));
const ReservationsPage = lazy(() => import('./pages/ReservationsPage/ReservationsPage'));
const PaymentMethodsPage = lazy(() => import('./pages/Dashboard/PaymentMethodsPage'));
const ReservationDetailsPage = lazy(() => import('./pages/ReservationDetailsPage/ReservationDetailsPage'));
const PayoutDetailsPage = lazy(() => import('./pages/Dashboard/PayoutDetailsPage'));

// Miscellaneous
const MapSearchPage = lazy(() => import('./pages/MapSearchPage/MapSearchPage'));
const Styleguide = lazy(() => import('./pages/Styleguide/index'));


const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <LocationProvider>
          <Router>
            <PageProvider>
              <Suspense fallback={<SuspenseFallback />}>
                <Routes>
                  <Route path="/" element={<MapSearchPage />} />
                  <Route path="/login" element={<MapSearchPage />} />
                  <Route path="/host" element={<Navigate replace to="/host/register" />} />
                  <Route path="/in-review" element={<PublicWrapper><AccountInReviewPage /></PublicWrapper>} />
                  <Route path="/:role?/register" element={<PublicWrapper><RegisterPage /></PublicWrapper>} />
                  <Route path="/password-reset/confirm" element={<PasswordResetConfirmPage />} />
                  <Route path="/password-reset/:token?" element={<PasswordResetPage />} />
                  <Route path="/onboarding/email" element={<PrivateWrapper><OnboardingEmailVerify /></PrivateWrapper>} />
                  <Route path="/onboarding/driver" element={<PrivateWrapper><DriverOnboardingPage /></PrivateWrapper>} />
                  <Route path="/profile" element={<PrivateWrapper><ProfilePage /></PrivateWrapper>} />
                  <Route path="/company" element={<PrivateWrapper><CompanyPage /></PrivateWrapper>} />
                  <Route path="/property/:propertyId" element={<PropertyPage />} />
                  <Route path="/book/:propertyId" element={<PrivateWrapper><PropertyBookPage /></PrivateWrapper>} />
                  <Route path="/book/confirmation/:reservationId" element={<PrivateWrapper><ReservationDetailsPage /></PrivateWrapper>} />
                  <Route path="/styleguide" element={<Styleguide />} />

                  <Route path="/dashboard/*" element={
                    <PrivateWrapper>
                      <Routes>
                        <Route path="/" element={<DashboardPage />} />
                        <Route path="amenities" element={<AmenitiesPage />} />
                        <Route path="accounts/approval/:accountId" element={<AccountApprovalPage />} />
                        <Route path="accounts/approval" element={<AccountApprovalTablePage />} />
                        <Route path="property/edit/:propertyId/:stepIndex?/:preview?" element={<EditPropertyPage />} />
                        <Route path="property/:propertyId/availability" element={<EditPropertyAvailabilityPage />} />
                        <Route path="properties/new/:stepIndex?/:preview?" element={<NewPropertyPage />} />
                        <Route path="properties/approval/:propertyId" element={<PropertyApprovalPage />} />
                        <Route path="properties/approval" element={<PropertyApprovalTablePage />} />
                        <Route path="properties" element={<DashboardPropertiesPage />} />
                        <Route path="fleet/edit/:fleetId/:stepIndex?" element={<EditFleetPage />} />
                        <Route path="fleet/:fleetId/user/:userId" element={<UserDetailsPage />} />
                        <Route path="fleet/:fleetId/*" element={<FleetMemberListPage />} />
                        <Route path="fleet/new/:stepIndex?" element={<NewFleetPage />} />
                        <Route path="fleet/add-driver" element={<DashboardFleetsPage />} />
                        <Route path="fleet" element={<DashboardFleetsPage />} />
                        <Route path="reservations/:reservationId" element={<ReservationDetailsPage />} />
                        <Route path="reservations" element={<ReservationsPage />} />
                        <Route path="payment-methods/*" element={<PaymentMethodsPage />} />
                        <Route path="payout-details/*" element={<PayoutDetailsPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </PrivateWrapper>
                  } />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </PageProvider>
          </Router>
        </LocationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;