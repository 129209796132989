import React from 'react';
import InputMask from 'react-input-mask';

const DateInput = (props) => {
    const inputClasses = 'peer order-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6 invalid:ring-red disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed';
    const combinedInputClasses = props.inputClassName ? `${inputClasses} ${props.inputClassName}` : inputClasses;

    return (
        <div className='relative flex flex-col'>
            <div className='relative order-2'>
                <InputMask
                    className={combinedInputClasses}
                    type='text'
                    id={props.idValue}
                    name={props.name}
                    placeholder={props.placeholder}
                    disabled={props.isDisabled}
                    aria-invalid={props.isInvalid}
                    required={props.required}
                    defaultValue={props.defaultValue}
                    // mask="99/99/9999"
                    {...props}
                />
                {props.iconLeft ? (
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        {props.iconLeft}
                    </div>
                ) : null}
                {props.iconRight ? (
                    <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
                        {props.iconRight}
                    </div>
                ) : null}
            </div>

            {props.errorMsg ? (
                <p className='mt-2 order-3 invisible peer-placeholder-shown:!invisible peer-placeholder-shown:!h-px peer-invalid:visible peer-invalid:h-4 h-px text-red text-sm transition-all'>
                    {props.errorMsg}
                </p>
            ) : null}

            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className={
                        props.showLabel
                            ? 'p order-1 block mb-2 text-sm font-medium leading-6 text-gray-900 peer-invalid:text-red'
                            : 'sr-only'
                    }
                >
                    {props.label} {props.required ? '' : <span className="text-gray-500 text-xs ml-3"> (optional)</span>}
                </label>
            ) : null}
        </div>
    );
};

export default DateInput;
