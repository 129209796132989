import React from 'react'

const FormTextarea = (props) => {
    return (
        <div className='flex flex-col'>
            <textarea
                className="peer order-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6 invalid:ring-red disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed"
                id={props.idValue}
                name={props.name}
                rows={props.rows ? props.rows : '3'}
                disabled={props.isDisabled}
                required={props.required}
                {...props}
            />
            {props.label ? (
                <label
                    htmlFor={props.idValue}
                    className={
                        props.showLabel
                            ? 'p order-1 text-sm font-medium leading-6 text-gray-900 peer-invalid:text-red mb-2'
                            : 'sr-only'
                    }
                >
                    {props.label} {props.required ? <span className="text-red-500">*</span> : ''}
                </label>
            ) : null}
        </div>
    )
}

export default FormTextarea
