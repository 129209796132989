import { loadGoogleMapsApi } from "../utils/googleMapsLoader";

class GooglePlacesService {
    constructor(apiKey) {
        this.apiKey = apiKey;
        this.google = null;
        this.autocompleteService = null;
        this.geocoder = null;
        this.debouncedGetPlacePredictions = this.debounce(this.getPlacePredictions.bind(this), 500);
        this.getGeoLocation = this.getGeoLocation.bind(this);
    }

    async load() {
        this.google = await loadGoogleMapsApi(this.apiKey);
        if (this.google) {
            this.autocompleteService = new this.google.maps.places.AutocompleteService();
            this.geocoder = new this.google.maps.Geocoder();
        } else {
            console.log("Failed to load the Google Maps API");
        }
    }

    getPlacePredictions(input) {
        return new Promise((resolve, reject) => {
            if (!this.autocompleteService) {
                reject("Autocomplete service not initialized.");
            }

            this.autocompleteService.getPlacePredictions(
                {
                    input,
                    componentRestrictions: { country: 'us' } // Restrict to the United States
                },
                (predictions, status) => {
                    if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
                        reject(status);
                    }
                    resolve(predictions);
                }
            );
        });
    }

    getGeoLocation(placeId) {
        return new Promise((resolve, reject) => {
            if (!this.geocoder) {
                reject("Geocoder service not initialized.");
            }

            this.geocoder
                .geocode({ placeId })
                .then(({ results }) => {
                    resolve(results);
                })
                .catch((e) => resolve(e))
        });
    }

    debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            return new Promise((resolve, reject) => {
                const later = () => {
                    clearTimeout(timeout);
                    try {
                        // Attempt to resolve the function's promise
                        resolve(func.apply(context, args));
                    } catch (error) {
                        reject(error);
                    }
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            });
        };
    }
}

export default GooglePlacesService;
