import { Loader } from '@googlemaps/js-api-loader';

let googleMapsPromise;

export const loadGoogleMapsApi = (apiKey) => {
  if (!googleMapsPromise) {
    const loader = new Loader({
      apiKey: apiKey,
      libraries: ['places'],
    });
    googleMapsPromise = loader.load()
      .catch((error) => {
        console.error('Failed to load Google Maps API:', error);
      });
  }
  return googleMapsPromise;
};
