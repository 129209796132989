import {
    MapIcon,
    HomeIcon,
    UsersIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    BanknotesIcon,
    TruckIcon,
    UserIcon,
    BuildingOfficeIcon,
    BuildingOffice2Icon
} from '@heroicons/react/24/outline';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext/AuthContext";

import { usePageContext } from '../../context/PageContext/PageContext';
// import { UserIcon } from '@heroicons/react/20/solid';

const DashboardSideNav = ({ role }) => {
    const navigate = useNavigate();
    const { profile, isAuthLoading } = useAuth();

    const { mobileSidebarOpen, setMobileSidebarOpen, showDesktopSideNav } = usePageContext();

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    let navItems = null;

    switch (role) {
        case 'DRIVER':
            navItems = [
                { name: 'Find Parking', href: '/', icon: TruckIcon, current: false },
                { name: 'Dashboard', href: '/dashboard', icon: ChartPieIcon, current: false },
                { name: 'Reservations', href: '/dashboard/reservations', icon: CalendarIcon, current: false },
                { name: 'Payment Methods', href: '/dashboard/payment-methods', icon: BanknotesIcon, current: false},
            ];
            break;
        case 'FLEET_MANAGER':
        case 'CARRIER_ADMIN':
            navItems = [
                { name: 'Find Parking', href: '/', icon: TruckIcon, current: false },
                { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false },
                { name: 'Fleets', href: '/dashboard/fleet', icon: UsersIcon, current: false },
                { name: 'Reservations', href: '/dashboard/reservations', icon: CalendarIcon, current: false },
                { name: 'Payment Methods', href: '/dashboard/payment-methods', icon: BanknotesIcon, current: false},
            ];
            break;
        case 'PROPERTY_MANAGER':
        case 'HOST_ADMIN':
            navItems = [
                { name: 'Find Parking', href: '/', icon: TruckIcon, current: false },
                { name: 'Dashboard', href: '/dashboard', icon: ChartPieIcon, current: false },
                { name: 'Properties', href: '/dashboard/properties', icon: MapIcon, current: false },
                { name: 'Reservations', href: '/dashboard/reservations', icon: CalendarIcon, current: false },
                { name: 'Payout Details', href: '/dashboard/payout-details', icon: BanknotesIcon, current: false },
            ];
            break;
        case 'PARKPRO_ADMIN':
            navItems = [
                { name: 'Find Parking', href: '/', icon: TruckIcon, current: false },
                { name: 'Dashboard', href: '/dashboard', icon: ChartPieIcon, current: false },
                { name: 'Property Amenities', href: '/dashboard/amenities', icon: UsersIcon, current: false },
                { name: 'Property Approval', href: '/dashboard/properties/approval', icon: MapIcon, current: false },
                { name: 'Account Approval', href: '/dashboard/accounts/approval', icon: UserIcon, current: false },
            ];
            break;
        default:
            navItems = [
                { name: 'Find Parking', href: '/', icon: TruckIcon, current: false },
            ];
            break;
    }

    if (navItems) {
        navItems = navItems.map((item) => ({
            ...item,
            current: window.location.pathname === item.href
        }));
    }

    let mainSideNavClasses = `fixed top-18 z-30 lg:z-auto lg:static transition-transform duration-300 ${mobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 max-w-72 w-10/12 h-[calc(100vh_-_80px)] lg:w-72 lg:h-full border-r border-gray-200 bg-white px-6 py-4 overflow-y-auto`;

    if (showDesktopSideNav === false) {
        mainSideNavClasses = `fixed top-18 z-30 transition-transform duration-300 ${mobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'} max-w-72 w-10/12 h-[calc(100vh_-_80px)] border-r border-gray-200 bg-white px-6 py-4 overflow-y-auto`;
    }

    return (
        <>
            <div className={mainSideNavClasses}>
                <nav className="flex flex-1 flex-col pt-6">
                    {isAuthLoading || !navItems ? (
                        <>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                            <div className="animate-pulse flex mt-4">
                                <div className="h-6 bg-gray-500/15 rounded-md w-full"></div>
                            </div>
                        </>
                    ) : (
                        <div className="w-full h-full lg:flex lg:flex-1 lg:text-2xl lg:mt-4">

                            <ul
                                role="list"
                                className="flex flex-1 flex-col gap-y-7"
                            >
                                <li>
                                    <ul role="list" className="-mx-2 space-y-3">
                                        {navItems.map((item) => (
                                            <li key={item.name}>
                                                <Link
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-gray-100 text-black"
                                                            : "text-gray-700 hover:text-black hover:bg-gray-100",
                                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current
                                                                ? "text-black"
                                                                : "text-gray-400 group-hover:text-black",
                                                            "h-6 w-6 shrink-0"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="border-t border-gray-200 mt-auto pt-6">
                                    <ul className="space-y-1.5">
                                        { profile ? (
                                            <>
                                            <li className="mt-auto">
                                                <Link
                                                    to="/profile"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-black"
                                                >
                                                    <UserIcon
                                                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-black"
                                                        aria-hidden="true"
                                                    />
                                                    My Profile
                                                </Link>
                                            </li>
                                            {(role === 'HOST_ADMIN' || role === 'CARRIER_ADMIN') && (
                                            <li className="mt-auto">
                                                <Link
                                                    to="/company"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-black"
                                                >
                                                    <BuildingOfficeIcon
                                                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-black"
                                                        aria-hidden="true"
                                                    />
                                                    Company Settings
                                                </Link>
                                            </li>
                                            )}
                                            </>
                                        ) : (
                                            <>
                                                <li className="mt-auto">
                                                    <Link
                                                        to="/login"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-black"
                                                    >
                                                        Sign In
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/login"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-black"
                                                    >
                                                        Sign Up For Free
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        )}
                </nav>
            </div>
            {mobileSidebarOpen && (
                <div className="fixed h-[calc(100vh_-_80px)] w-full left-0 top-18 bg-black bg-opacity-50 z-20 lg:hidden" onClick={() => setMobileSidebarOpen(false)}></div>
            )}
        </>
    );
};

export default DashboardSideNav;