import { useState, useEffect, useRef, Fragment } from "react";
import FormElement from "../../Forms/Forms";
import ThreeDotsLoader from "../../ThreeDotsLoader/ThreeDotsLoader";
import GooglePlacesService from "../../../services/GooglePlacesService";
import { Transition } from "@headlessui/react";
import MagnifyingGlassIcon from "../../../assets/icons/icon-magnifying-glass-blue.svg";
import { useLocationContext } from "../../../context/LocationContext/LocationContext";

const SearchLocations = ({ a, searchInputPlaceholder, searchInputFillContainer }) => {
    const [predictionFocusIndex, setPredictionFocusIndex] = useState(-1);
    const [predictionDrawerOpen, setPredictionDrawerOpen] = useState(false);
    const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] = useState(false);
    const predictionDrawerRef = useRef(null);

    const { locationSearchValue, setLocationSearchValue, setDestinationSelected, setSearchCoordinates, setMapBounds } = useLocationContext();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (predictionDrawerRef.current && !predictionDrawerRef.current.contains(event.target)) {
                setPredictionDrawerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [predictionDrawerRef]);

    const [predictions, setPredictions] = useState([]);

    const googlePlacesService = new GooglePlacesService(process.env.REACT_APP_GOOGLE_API_KEY);

    useEffect(() => {
        const fetchPredictions = async () => {
            setIsPlacePredictionsLoading(true);
            await googlePlacesService.load();
            if (locationSearchValue.description && locationSearchValue.description.length > 0) {
                try {
                    const predictions = await googlePlacesService.debouncedGetPlacePredictions(locationSearchValue.description);
                    setPredictions(predictions || []);
                } catch (error) {
                    console.error(error);
                }
            }
            setIsPlacePredictionsLoading(false);
        };
        fetchPredictions();
    }, [locationSearchValue.description]);

    const closePredictions = () => {
        setPredictionFocusIndex(-1);
        setPredictionDrawerOpen(false);
    };

    const parseAddress = ({addressComponents, description}) => {
        const addressMap = {
            street_number: 'short_name',
            route: 'long_name',
            subpremise: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        let address = {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            description: description
        };

        addressComponents.forEach(component => {
            const addressType = component.types[0];
            if (addressMap[addressType]) {
                const val = component[addressMap[addressType]];
                switch (addressType) {
                    case 'street_number':
                        address.street1 = val + ' ' + (address.street1 || '');
                        break;
                    case 'route':
                        address.street1 += val;
                        break;
                    case 'subpremise':
                        address.street2 = val;
                        break;
                    case 'locality':
                        address.city = val;
                        break;
                    case 'administrative_area_level_1':
                        address.state = val;
                        break;
                    case 'postal_code':
                        address.zip = val;
                        break;
                    default:
                        break;
                }
            }
        });

        return address;
    };

    const selectPrediction = async (prediction) => {
        await googlePlacesService.load();
        const geocoded = await googlePlacesService.getGeoLocation(prediction.place_id);
        const northEastSearch = geocoded[0].geometry.viewport.getNorthEast();
        const southWestSearch = geocoded[0].geometry.viewport.getSouthWest();
        const address = parseAddress({ addressComponents: geocoded[0].address_components, description: prediction.description });
        setMapBounds({
            northEast: { lat: northEastSearch.lat(), lng: northEastSearch.lng() },
            southWest: { lat: southWestSearch.lat(), lng: southWestSearch.lng() }
        });
        setSearchCoordinates({
            lat: geocoded[0].geometry.location.lat(),
            lng: geocoded[0].geometry.location.lng()
        });
        setLocationSearchValue(address);
        setDestinationSelected(true);
        setPredictionFocusIndex(-1);
        closePredictions();
    };

    const handlePredictionClick = async (event, prediction) => {
        await selectPrediction(prediction);
    };

    const handlePredictionKeyPress = (event, prediction) => {
        if (event.key === "Enter") {
            selectPrediction(prediction);
        }
    };

    const handleFindLocationsChange = async (event) => {
        setLocationSearchValue({...locationSearchValue, description: event.target.value});
        setDestinationSelected(false);
        setPredictionDrawerOpen(true);
    };

    const handlePredictionHover = (event) => {
        const predictions = document.querySelectorAll(".prediction");
        predictions.forEach(prediction => prediction.blur());
        event.target.focus();
    };

    return (
        <div className="max-w-lg w-full relative">
            <FormElement
                variant="input"
                value={locationSearchValue.description ? locationSearchValue.description : ''}
                iconLeft={
                    <div className="flex items-center justify-center w-8 h-8">
                        <img src={MagnifyingGlassIcon} alt="Search" className="h-5 w-5" />
                    </div>
                }
                iconRight={
                    <div className="flex items-center justify-center w-8 h-8">
                        {isPlacePredictionsLoading ? <ThreeDotsLoader /> : ''}
                    </div>
                }
                onFocus={(event) => event.target.select()}
                placeholder={searchInputPlaceholder}
                onChange={handleFindLocationsChange}
                className={`w-full px-4 pb-0 pt-[1px] pl-12 h-10 border-0 ${searchInputFillContainer ? 'h-full focus:ring-0' : 'rounded-md focus:ring-4 focus:ring-secondary'} focus:outline-none transition duration-150 ease-in-out text-black placeholder-gray-600`}
            />
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={predictionDrawerOpen && !isPlacePredictionsLoading && predictions.length > 0}
            >
                <div
                    ref={predictionDrawerRef}
                    className={`absolute z-20 w-full bg-white border border-gray-300 rounded-md shadow-md shadow-gray-200 mt-2`}
                >
                    {!isPlacePredictionsLoading &&
                        predictions.map((prediction, index) => (
                            <div
                                onBlur={() => setPredictionFocusIndex(-1)}
                                onFocus={() => setPredictionFocusIndex(index)}
                                onClick={(event) =>
                                    handlePredictionClick(event, prediction)
                                }
                                onKeyUp={(event) =>
                                    handlePredictionKeyPress(event, prediction)
                                }
                                key={prediction.place_id}
                                className={`prediction py-3 px-6 first:rounded-t-md last:rounded-b-md cursor-pointer outline-none ${
                                    predictionFocusIndex === index ? "bg-tertiary" : ""
                                } active:bg-tertiary-dark`}
                                tabIndex={0}
                                onMouseEnter={handlePredictionHover}
                            >
                                {prediction.description}
                            </div>
                        ))}
                </div>
            </Transition>
        </div>
    );
};

export default SearchLocations;
