import React, { createContext, useContext, useState, useEffect } from 'react';

const LocationSearchContext = createContext();


export const LocationProvider = ({ children }) => {
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [destinationSelected, setDestinationSelected] = useState(false);
  const [searchCoordinates, setSearchCoordinates] = useState({ lat: null, lng: null });
  const [mapBounds, setMapBounds] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [resetMap, setResetMap] = useState(false);

  const initialMapBounds = {
    "northEast": {lat: 45.81457370563775, lng: -73.6386277125},
    "southWest": {lat: 33.2308029302877, lng: -123.4726120875}
  };

  // Initialize state from local storage
  useEffect(() => {
    const storedLocationSearchValue = sessionStorage.getItem('locationSearchValue');
    const storedDestinationSelected = sessionStorage.getItem('destinationSelected');
    const storedSearchCoordinates = sessionStorage.getItem('searchCoordinates');
    const storedMapBounds = sessionStorage.getItem('mapBounds');

    if (storedLocationSearchValue) {
      setLocationSearchValue(storedLocationSearchValue);
    }

    try {
      if (storedDestinationSelected) {
        setDestinationSelected(JSON.parse(storedDestinationSelected));
      }

      if (storedSearchCoordinates) {
        setSearchCoordinates(JSON.parse(storedSearchCoordinates));
      }

      if (storedMapBounds) {
        setMapBounds(JSON.parse(storedMapBounds));
      }
    } catch (error) {
      console.error('Error parsing stored data:', error);
    }

  }, []);

  // Update local storage whenever state changes
  useEffect(() => {
    sessionStorage.setItem('locationSearchValue', locationSearchValue);
  }, [locationSearchValue]);

  useEffect(() => {
    sessionStorage.setItem('destinationSelected', JSON.stringify(destinationSelected));
  }, [destinationSelected]);

  useEffect(() => {
    sessionStorage.setItem('searchCoordinates', JSON.stringify(searchCoordinates));
  }, [searchCoordinates]);

  useEffect(() => {
    sessionStorage.setItem('mapBounds', JSON.stringify(mapBounds));
  }, [mapBounds]);

  const clearLocationSearch = () => {
    setLocationSearchValue("");
    setSearchCoordinates({ lat: null, lng: null });
    setMapBounds(initialMapBounds);
    setDestinationSelected(false);
    setResetMap(true);
    setTimeout(() => {
      setResetMap(false);
    }, 100);
  };

  return (
    <LocationSearchContext.Provider value={{
      locationSearchValue,
      setLocationSearchValue,
      destinationSelected,
      setDestinationSelected,
      searchCoordinates,
      setSearchCoordinates,
      mapBounds,
      setMapBounds,
      isLoading,
      setIsLoading,
      clearLocationSearch,
      resetMap
    }}>
      {children}
    </LocationSearchContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationSearchContext);
