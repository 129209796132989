import React from 'react';
import Input from './input';
import Radio from './radio';
import Checkbox from './checkbox';
import Textarea from './textarea';
import Dropdown from './dropdown';
import File from './file';
import Select from './select';
import Phone from './phone';
import Date from './date';

const FormElement = (props) => {
    let markup = '<></>'
    let { variant } = props

    //ensure each input has a unique id
    const rand = 1 + Math.random() * (100 - 1)
    const idValue = props.id ? props.id : 'field_' + rand
    const extraFields = {
        // type: variant,
        idValue: idValue
    }

    switch (variant) {
        case 'input':
            return <Input {...props} {...extraFields} />
        case 'phone':
            return <Phone {...props} {...extraFields} />
        case 'date':
            return <Date {...props} {...extraFields} />
        case 'textarea':
            return <Textarea {...props} {...extraFields} />
        case 'radio':
            return <Radio {...props} {...extraFields} />
        case 'checkbox':
            return <Checkbox {...props} {...extraFields} />
        case 'dropdown':
            return <Dropdown {...props} {...extraFields} />
        case 'select':
            return <Select {...props} {...extraFields} />
        case 'file':
            return <File {...props} {...extraFields} />
        default:
            return <div {...props} {...extraFields}>{markup}</div>
    }
}

export default FormElement
