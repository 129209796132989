import React, { useEffect, useContext } from 'react';
import { useAuth } from '../../context/AuthContext/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';

const PrivateWrapper = ({ children }) => {
  const location = useLocation();
  const { user, isAuthLoading } = useAuth();

  if (isAuthLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};


export default PrivateWrapper;
