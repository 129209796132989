import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const UserMenu = ({ user, links }) => {
    return (
        <Menu as="div" className="relative z-30">
            <div>
                <Menu.Button className="group relative flex max-w-xs items-center rounded-full text-lg pl-2 hover:bg-secondary/40 active:bg-secondary transition">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <ChevronDownIcon className="mr-1 h-4 w-4 text-white" />
                    {user.profilePictureURL ? (
                        <img
                            src={user.profilePictureURL}
                            alt="User"
                            className="w-8 h-8 md:w-10 md:h-10 rounded-full"
                        />
                    ) : (
                        <span className="inline-flex items-center justify-center rounded-full bg-gray-500 text-white h-10 w-10 group-hover:bg-gray-500">
                            <span className="font-medium leading-none text-white">
                                {user.initials}
                            </span>
                        </span>
                    )}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 min-w-64 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-1.5">
                    <div className="px-4 py-2 text-xs text-gray-700">
                        Signed in as <div className="text-sm font-bold">{user.email}</div>
                    </div>
                    <hr className="my-1 bg-gray-100" />
                    {links && links.map((item, index) => (
                        <Fragment key={index}>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={item.action}
                                        className={`block px-4 py-1.5 text-sm text-gray-700 w-full text-left ${
                                            active ? 'bg-gray-200' : ''
                                        }`}
                                    >
                                        {item.name}
                                    </button>
                                )}
                            </Menu.Item>
                            {item.divider && <hr className="my-1 bg-gray-100" />}
                        </Fragment>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default UserMenu;