const roleNameMap = {
    CARRIER_ADMIN: {
        shortName: "Carrier Admin",
        longName: "Carrier Administrator",
    },
    FLEET_MANAGER: {
        shortName: "Fleet Manager",
        longName: "Fleet Manager",
    },
    HOST_ADMIN: {
        shortName: "Host Admin",
        longName: "Host Administrator",
    },
    PROPERTY_MANAGER: {
        shortName: "Property Manager",
        longName: "Property Manager",
    },
    PARKPRO_ADMIN: {
        shortName: "ParkPro Admin",
        longName: "ParkPro Administrator",
    },
    DRIVER: {
        shortName: "Driver",
        longName: "Pro Driver",
    },
};

export {
    roleNameMap,
}