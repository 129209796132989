import { useEffect, useCallback } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

const sizeClasses = {
    full: "w-full max-w-screen max-h-screen",
    "full-width-and-height": "w-full h-screen max-w-screen max-h-screen",
    "3xl": "w-full max-w-3xl max-h-3xl",
    "2xl": "w-3/4 max-w-2xl max-h-2xl",
    xl: "w-1/2 max-w-xl max-h-xl",
    large: "w-full max-w-lg max-h-lg",
    medium: "w-full max-w-md max-h-md",
    small: "w-full max-w-sm max-h-min",
};

const Modal = ({
    show = false,
    allowCloseVeilClick = false,
    handleClose,
    size = "large",
    children,
    className = "",
    centeredFullscreen = true,
    showVeil = true,
    showTopCloseButton = true,
}) => {
    const handleOutsideClick = useCallback((e) => {
        if (e.target === e.currentTarget && allowCloseVeilClick) {
            handleClose();
        }
    }, [allowCloseVeilClick, handleClose]);

    useEffect(() => {
        const modal = document.querySelector('[data-modal]');
        if (allowCloseVeilClick && modal) {
            modal.addEventListener('click', handleOutsideClick);
            return () => modal.removeEventListener('click', handleOutsideClick);
        }
    }, [allowCloseVeilClick, handleOutsideClick]);

    if (!show) return null;

    return (
        <div
            data-modal
            className={`
                fixed inset-0 z-50 overflow-auto
                ${showVeil ? 'bg-black bg-opacity-30' : ''}
                ${centeredFullscreen ? 'flex justify-center items-center' : ''}
                ${className}
            `}
        >
            <div className={`${sizeClasses[size] || sizeClasses.large} relative p-4 max-h-screen`}>
                <div className='relative bg-white rounded-lg shadow h-full'>
                    {showTopCloseButton && (
                        <button
                            type="button"
                            onClick={handleClose}
                            className="absolute top-3 end-2.5 text-gray-600 bg-white hover:bg-gray-200 hover:text-gray-900 active:bg-gray-300 active:text-black rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center z-50 transition"
                        >
                            <XMarkIcon className="w-7 h-7" />
                            <span className="sr-only">Close modal</span>
                        </button>
                    )}
                    <div className="p-4 md:p-5 text-center h-full">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;