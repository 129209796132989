import Spinner from './Spinner/Spinner';

const SuspenseFallback = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <Spinner size="large" />
      </div>
    </div>
  );
};

export default SuspenseFallback;
