const colors = require('tailwindcss/colors');

var config = {
    theme: {
        extend: {
            colors: {
                primary: 'rgb(var(--color-primary) / <alpha-value>)',
                'primary-dark': 'rgb(var(--color-primary-dark) / <alpha-value>)',
                secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
                'secondary-dark': 'rgb(var(--color-secondary-dark) / <alpha-value>)',
                tertiary: 'rgb(var(--color-tertiary) / <alpha-value>)',
                'tertiary-dark': 'rgb(var(--color-tertiary-dark) / <alpha-value>)',
                'base-text': 'rgb(var(--color-secondary-dark) / <alpha-value>)',
                highlight: colors.amber['400'],
            },
            boxShadow: ({ theme }) => ({
                invalid: `inset 0 0 1px 1px ${theme('colors.red', '#ec133b')}`
            })
        },
        fontFamily: {
            primary: ['"Inter",  sans-serif', { fontVariationSettings: '"slnt" var(--slnt-axis, 0)' },]
        },
        //font-variation-settings: "slnt" var(--slnt-axis, 0);
        fontSize: {
            xs: ['0.75rem', { lineHeight: '1rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            sm: ['0.875rem', { lineHeight: '1.25rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            base: ['1rem', { lineHeight: '1.5rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            lg: ['1.125rem', { lineHeight: '1.75rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            xl: ['1.25rem', { lineHeight: '1.75rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '2xl': ['1.5rem', { lineHeight: '2rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '3xl': ['1.9rem', { lineHeight: '2.2rem', letterSpacing: '.0px', fontWeight:  'var(--wght-axis, 400)'}],
            '4xl': ['2.4rem', { lineHeight: '2.5rem', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '5xl': ['3rem', { lineHeight: '1', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '6xl': ['3.75rem', { lineHeight: '1', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '7xl': ['4.5rem', { lineHeight: '1', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '8xl': ['6rem', { lineHeight: '1', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}],
            '9xl': ['8rem', { lineHeight: '1', letterSpacing: '0px', fontWeight:  'var(--wght-axis, 400)'}]
        },
        h: {
            1: '@apply text-5xl @md:text-6xl @lg:text-7xl leading-8 font-primary',
            2: '@apply text-3xl @md:text-4xl @lg:text-5xl font-primary',
            3: '@apply text-2xl @md:text-3xl @lg:text-4xl font-primary',
            4: '@apply text-xl @md:text-2xl @lg:text-3xl font-primary',
            5: '@apply text-lg @md:text-xl @lg:text-2xl font-primary',
            6: '@apply text-base @md:text-lg @lg:text-xl font-primary'
        },
        p: {
            '': '@apply text-base-text text-xs @lg:text-sm',
            '-small': '@apply text-base-text text-xs @lg:text-xs',
            '-large': '@apply text-base-text text-base @lg:text-lg'
        },
        buttons: {
            types: {
                'primary': 'bg-primary border-primary enabled:hover:bg-primary-dark enabled:hover:border-primary-dark enabled:active:bg-primary-dark text-white enabled:hover:text-white disabled:opacity-30',
                'secondary': 'bg-secondary enabled:hover:bg-secondary-dark enabled:hover:border-secondary-dark text-white enabled:hover:text-white enabled:active:text-white enabled:active:bg-secondary-dark enabled:active:border-quaternary border-secondary disabled:opacity-30',
                'secondary-alternate': 'bg-white border-secondary text-secondary',
                'tertiary': 'bg-tertiary border-tertiary enabled:hover:bg-tertiary-dark enabled:hover:border-tertiary-dark enabled:active:bg-tertiary-dark text-black enabled:hover:text-black disabled:opacity-30',
                'text-link': 'underline underline-offset-1 !border-0 enabled:hover:text-secondary cursor-pointer',
                'text-link-nodecorations': '!border-0 cursor-pointer',
                'danger': 'border-transparent text-white bg-red-600'
            },
            classes: {
                'small': 'px-2 py-2',
                'large': 'px-4 py-3',
                'base': 'text-sm font-medium rounded-md border-2 font-primary tracking-wide relative flex items-center justify-center focus:outline-none transition ease-in-out duration-150'
            }
        },
        screens: {
            sm: '576px',
            md: '768px',
            lg: '1024px',
            xl: '1440px',
            xxl: '1536px'
        },
    }
}

// Styleguide sections - Dynamic building
var fs = config.theme.fontSize
var c = config.theme.extend.colors

config.theme = Object.assign(
    {
        'styleguide-nav': {
            identity: 'identity',
            colors: 'colors',
            fonts: 'fonts',
            headers: 'headers',
            paragraphs: 'paragraphs',
            buttons: 'buttons',
            accordion: 'accordion',
            inputs: 'inputs',
            tabs: 'tabs',
            modals: 'modals',
            datepicker: 'date picker'
        },
        paragraphs: {
            'p-small': [fs['xs'], fs['xs']],
            p: [fs['xs'], fs['sm']],
            'p-large': [fs['base'], fs['lg']]
        },
        colormaps: {
            main: {
                primary: c.primary,
                white: colors.white,
                secondary: c.secondary,
                tertiary: c.tertiary,
            },
            accent: {
                'primary-dark': c['primary-dark'],
                'tertiary-dark': c['tertiary-dark'],
                'secondary-dark': c['secondary-dark'],
                highlight: c.highlight
            }
        },
        letterSpacing: {
            tightest: '-.075em',
            tighter: '-.05em',
            tight: '-.025em',
            normal: '0',
            wide: '.025em',
            wider: '.05em',
            widest: '.25em',
            '.5x': '.5em',
            '1x': '1em',
            '2x': '2em'
        }
    },
    config.theme
)

module.exports = config;