import React, { useState } from 'react';
import { Link } from "react-router-dom";

//Logo Stacked
import LogoStack0 from "../../../assets/images/logos/park-pro-logo-stacked-border-0.svg";
import LogoStack1 from "../../../assets/images/logos/park-pro-logo-stacked-border-1.svg";
import LogoStack2 from "../../../assets/images/logos/park-pro-logo-stacked-border-2.svg";
import LogoStackDark0 from "../../../assets/images/logos/park-pro-logo-stacked-border-0-white.svg";
import LogoStackDark1 from "../../../assets/images/logos/park-pro-logo-stacked-border-1-white.svg";
import LogoStackDark2 from "../../../assets/images/logos/park-pro-logo-stacked-border-2-white.svg";
import LogoStackInverse1 from "../../../assets/images/logos/park-pro-stacked-border-1-inverse.svg";
import LogoStackInverse2 from "../../../assets/images/logos/park-pro-stacked-border-2-inverse.svg";

//Logo Long
import LogoLong0 from "../../../assets/images/logos/park-pro-logo-long-border-0.svg";
import LogoLong1 from "../../../assets/images/logos/park-pro-logo-long-border-1.svg";
import LogoLong2 from "../../../assets/images/logos/park-pro-logo-long-border-2.svg";

//Logo Extras //To be dealt with later
import LogoBadge from "../../../assets/images/logos/park-pro-logo-stacked-badge.svg";

/*
* * Example Usage *
    <Logo
        link='/'
        tagline
        idValue='desktop-logo'
        variant='stacked'
        dark
        imgClasses='w-3/4 mx-auto mb-4 drop-shadow-md'
        containerClasses='md:hidden'
        border='1'
    />
*/
const Logo = (props) => {

const imgClasses = '';

var border = props.border ? props.border : '0';
var logo;
var logoString = 'Logo';
    logoString = logoString + (props.variant.toLowerCase().includes('stack') ? 'Stack' : 'Long');
    logoString = logoString + (props.dark ? 'Dark' : props.inverse ? 'Inverse' : '') + border;

switch (logoString) {
    case 'LogoStack0':
        logo = LogoStack0;
        break;
    case 'LogoStack1':
        logo = LogoStack1;
        break;
    case 'LogoStack2':
        logo = LogoStack2;
        break;
    case 'LogoStackDark0':
        logo = LogoStackDark0;
        break;
    case 'LogoStackDark1':
        logo = LogoStackDark1;
        break;
    case 'LogoStackDark2':
        logo = LogoStackDark2;
        break;
    case 'LogoStackInverse0':
        logo = LogoStackInverse1;
        break;
    case 'LogoStackInverse1':
        logo = LogoStackInverse1;
        break;
    case 'LogoStackInverse2':
        logo = LogoStackInverse2;
        break;
    case 'LogoLong0':
        logo = LogoLong0;
        break;
    case 'LogoLong1':
        logo = LogoLong1;
        break;
    case 'LogoLong2':
        logo = LogoLong2;
        break;
    case 'LogoLongDark0':
        logo = LogoLong0;
        break;
    case 'LogoLongDark1':
        logo = LogoLong1;
        break;
    case 'LogoLongDark2':
        logo = LogoLong2;
        break;
    default:
        logo = LogoLong0;
}

var img = <img
            src={logo}
            id={props.idValue || ''}
            alt="Park Pro Logo"
            className={props.imgClasses ? `${imgClasses} ${props.imgClasses}` : imgClasses}
        />;

    return (
        <div className={props.containerClasses ? props.containerClasses : ''}>
            {props.link ? (
                <Link to={props.link}>
                    {img}
                </Link>
            ) : img}

            {props.tagline ? (
               <p className="text-primary font-bold text-sm text-center leading-4 tracking-0.5">
                    {props.tagline !== true ? props.tagline : 'PARK LIKE A PRO'}
                </p>
            ) : null}
        </div>
    )
}

export default Logo
