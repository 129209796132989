import React, { useState } from 'react'
import Button from '../Button/Button'
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import FormElement from '../Forms/Forms';

const PasswordResetForm = () => {
    const [email, setEmail] = useState("");
    const [passwordResetMessage, setPasswordResetMessage] = useState(null);
    const [passwordResetError, setPasswordResetError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handlePasswordResetError = (err) => {
        console.error(err);
        setPasswordResetError(err);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordResetError(null); // Clear any previous login errors

        if (email.trim().length === 0 ) {
            handlePasswordResetError("Email cannot be empty.");
            return;
        }

        setIsLoading(true); // start loading

        const delayPromise = new Promise(resolve => setTimeout(resolve, 2000));
        const requestPromise = axios.post('/auth/password-reset/send', {
            email: email
        });

        try {
            const response = await requestPromise;
            if (response.status === 200) {
                setPasswordResetMessage(response.data.msg);
                await delayPromise; // If the request finishes before the delay, wait for the delay
                navigate('/')
            } else {
                alert(response.data.msg);
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error(err);
            handlePasswordResetError(err.response.data.msg);
            await delayPromise; // If the request finishes before the delay, wait for the delay
            setIsLoading(false); // stop loading
        }
    };
  return (
    <>
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-left">
        <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
                <FormElement
                    id="email"
                    name="email"
                    type="email"
                    variant="input"
                    autoComplete="email"
                    label="Email address"
                    showLabel
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                    required
                />
            </div>
            {passwordResetError && (
                <div className="text-red-500 text-sm font-semibold mt-2 text-center">
                    {passwordResetError}
                </div>
            )}
            {passwordResetMessage && (
                <div className="text-green-500 text-sm font-semibold mt-2 text-center">
                    {passwordResetMessage}
                </div>
            )}
        <div>
            <Button
                type="submit"
                variant="primary"
                size="normal"
                className="w-full"
                loading={isLoading}
                disabled={isLoading}
            >
                Send password reset link
            </Button>
            </div>
        </form>
    </div>
    </>
    )
}

export default PasswordResetForm